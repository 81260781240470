








































































import employeeService from '@/services/employee.service';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import Vue from 'vue';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    LoadingIndicator
  },
  data() {
    return {
      iCalUrl: '',
      webcalUrl: ''
    };
  },
  computed: {
    ...mapStores(useUiStore)
  },
  async created() {
    this.iCalUrl = await employeeService.findICalUrl();
    this.webcalUrl = `webcal://${this.iCalUrl
      .replace('https://', '')
      .replace('http://', '')}`;
  },
  methods: {
    copyICalUrl() {
      navigator.clipboard.writeText(this.iCalUrl);
      this.uiStore.alertSuccess(this.$t('toast.copiedToClipboard'));
    }
  }
});
